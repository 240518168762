import React from 'react';
import './Common/common.css'; // src/Common/common.css

//import Free_contents from '/Board/free_contents';

function Home() {
    // const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//     <div style={{fontSize:'20px', fontWeight: 'bold', marginBottom: '20px'}}>free.js</div>
//     <Free_contents />
//     </React.StrictMode>
// );
  return <h1>Home</h1>;
}

export default Home;