import React from 'react';
import ReactDOM from 'react-dom/client';
function Error() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
        <div style={{fontSize:'20px', fontWeight: 'bold', marginBottom: '20px'}}>E R R O R</div>
        </React.StrictMode>
    );
  return <div></div>;
}

export default Error;