// Paging.js
import React from 'react';

function Paging({ currentPage, totalPages, handlePageChange, handlePrevBlock, handleNextBlock, pageBlockSize }) {
    
  return (
    <div style={{ marginTop: "5px", marginBottom: "25px" }}>
      {/* 이전 10페이지 버튼 */}
      <div className={`pagenum ${currentPage === 1 ? 'disabled' : ''}`} onClick={handlePrevBlock}>◀ 이전</div>

      {/* 페이지 번호 */}
      {
        [...Array(Math.min(totalPages, pageBlockSize))].map((_, index) => {
          // 현재 페이지를 기준으로, 페이지 번호 계산
          const page = Math.max(1, (Math.floor((currentPage - 1) / pageBlockSize) * pageBlockSize) + index + 1);

          // 페이지 번호가 totalPages를 넘지 않도록 제한
          if (page > totalPages) return null;

          return (
            <div
              key={page}
              className={`${currentPage === page ? 'pagenum_active' : 'pagenum'}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </div>
          );
        })
      }

      {/* 다음 10페이지 버튼 */}
      <div className={`pagenum ${currentPage === totalPages ? 'disabled' : ''}`} onClick={handleNextBlock}>다음 ▶</div>
    </div>
  );
}

export default Paging;
