import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Router는 제거
import FreeList from './Board/Free/List'; 
import FreeDetail from './Board/Free/Detail'; 
import FreeWrite from './Board/Free/Write'; 
import Home from './Home'; 
import Error from './Error';

function App() {
  return (
    <Routes>
      <Route path="*" element={<Error />} />
      <Route path="/" element={<Home />} />
      <Route path="/Free" element={<FreeList />} />
      <Route path="/Free/:idx" element={<FreeDetail />} />
      <Route path="/Free/Write" element={<FreeWrite />} />
    </Routes>
  );
}

export default App;
