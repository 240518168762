import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import { API_URL, MSG } from '../../Common/common';
import SubMenu from '../../Layout/SubMenu';
import Paging from '../../Layout/Paging';  // Paging 컴포넌트 임포트

function List() {
  const [dataList, setData] = useState([]);  // 게시판 목록
  const [totalCount, setTotalCount] = useState(0);  // 게시물 총 개수
  const [totalPages, setTotalPages] = useState(0);  // 총 페이지 수
  const [currentPage, setCurrentPage] = useState(1);  // 현재 페이지
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 로딩 상태
  const pageSize = 30;  // 페이지당 게시물 수
  const pageBlockSize = 10;  // 페이지 번호 블록 크기 (10페이지 단위로 이동)
  const searchCondition = '';
  const searchKeyword = '';
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/board/free/list?page=${currentPage}&pageSize=${pageSize}&searchCondition=${searchCondition}&searchKeyword=${searchKeyword}`); // API URL
        const { board_list, total_count } = response.data; // 게시판 목록과 총 개수 분리
        
        if (Array.isArray(board_list)) {
          setData(board_list); // 게시판 목록 상태 설정
        } else {
          setData([]); // 배열이 아니면 빈 배열 설정
        }

        setTotalCount(total_count); // 총 개수 상태 설정
        setTotalPages(Math.ceil(total_count / pageSize)); // 총 페이지 수 계산
      } catch (err) {
        console.log(err.message);
        setError(MSG.error_db);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handle_btnToDetailClick = (board_idx) => {
    const currentPath = window.location.pathname;
    window.location.href = currentPath + '/' +  board_idx;  
  };

  
  // 페이지 버튼 클릭 시 처리
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);  // 페이지 변경
    }
  };

  // 이전 10페이지로 이동
  const handlePrevBlock = () => {
    const newPage = Math.max(currentPage - pageBlockSize, 1);
    setCurrentPage(newPage);
  };

  // 다음 10페이지로 이동
  const handleNextBlock = () => {
    const newPage = Math.min(currentPage + pageBlockSize, totalPages);
    setCurrentPage(newPage);
  };

  return (
    <table className="tbl_layout" cellSpacing="0" cellPadding="0" style={{ width: '100%', height: '100%', tableLayout: "fixed" }}>
      <thead></thead>
      <tbody>
        <tr>
          <td style={{ textAlign: "right", paddingTop: "10px" }}></td>
          <td style={{ width: "850px", textAlign: "center", paddingTop: "10px" }}>

            {/* SUB MENU */}
            <SubMenu />

            {/* 게시판 목록 테이블 */}
            <table className="boardList" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
              <colgroup>
                <col width="60" />
                <col />
                <col width="140" />
                <col width="65" />
                <col width="40" />
                <col width="40" />
                <col width="40" />
              </colgroup>
              <thead>
                <tr>
                  <th>No</th>
                  <th>제 목</th>
                  <th>작성자</th>
                  <th>등록</th>
                  <th>추천</th>
                  <th>비추</th>
                  <th>조회</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(dataList) && dataList.length > 0 ? (
                  dataList.map(data => (
                    <tr reguserid={data.reguserid} key={data.idx} type="data">
                      <td className="list-num" style={{ fontSize: "9px" }}>{data.idx}</td>
                      <td className="list-title" onClick={(e) => { handle_btnToDetailClick(data.idx); }}>{data.title}</td>
                      <td className="list-name">{data.username}</td>
                      <td className="list-num">{data.regdate}</td>
                      <td className="list-num">{data.recommend}</td>
                      <td className="list-num">{data.notrecommend}</td>
                      <td className="list-num">{data.readcnt}</td>
                    </tr>
                  ))
                ) : (
                  <tr><td colSpan={7} style={{ paddingTop: "8px" }}>게시글이 없습니다.</td></tr>
                )}
              </tbody>
            </table>

            {/* BUTTONS */}
            <table style={{width: "100%", marginTop: "10px"}}>
              <thead></thead>
              <tbody>
                <tr>
                  <td style={{width: "150px"}}></td>
                  <td></td>
                  <td style={{width: "250px"}}>
                    <div className="btn1" style={{marginLeft: "auto", marginRight: "0px"}}>글쓰기</div>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* PAGING */}
            <Paging
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePrevBlock={handlePrevBlock}
              handleNextBlock={handleNextBlock}
              pageBlockSize={pageBlockSize}
            />

          </td>
          <td style={{ textAlign: "left" }}></td>
        </tr>
      </tbody>
    </table>
  );
}

export default List;
