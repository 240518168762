import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { API_URL, MSG } from '../../Common/common';
import SubMenu from '../../Layout/SubMenu';
import { useParams, useNavigate  } from 'react-router-dom';  // URL 파라미터를 받기 위해 필요

function Write() {
  const { idx } = useParams(); // URL에서 idx 값을 가져옴
  const [data, setData] = useState({ title: '', content: '' });
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 로딩 상태
  const userid = ''; // 사용자의 ID, 필요에 따라 추가
  const ref_title = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (idx) {
          const response = await axios.get(`${API_URL}/api/board/free/detail?idx=${idx}&userid=${userid}`); // API URL
          setData(response.data); 
          console.log('data:' + response.data);
        }
      } catch (err) {
        console.log(err.message);
        setError(MSG.error_db + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [idx]);

  // 컴포넌트가 처음 렌더링된 후에 ref_title에 포커스를 맞추기 위해 useEffect 수정
  useEffect(() => {
    // ref_title이 정상적으로 렌더링된 후에 포커스를 맞추는 로직
    const timer = setTimeout(() => {
      if (ref_title.current) {
        ref_title.current.focus();
      }
    }, 100);  // 100ms 지연 후 포커스 설정

    return () => clearTimeout(timer);  // 컴포넌트가 언마운트되면 타이머 클리어
  }, []); // 빈 배열을 넣으면 컴포넌트가 처음 렌더링된 후에만 실행됩니다.

  const handle_btnSaveClick = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/board/free/update`, {
        idx, // 수정 시 idx 필요
        title: data.title,
        content: data.content,
        userid: userid, // 사용자 ID를 필요에 따라 추가
      });
      if (response.data.success) {
        alert('저장되었습니다.');
        window.location.href = '/board/list'; // 저장 후 목록으로 이동 (경로는 필요에 따라 수정)
      } else {
        alert('저장 실패: ' + response.data.message);
      }
    } catch (err) {
      console.log(err.message);
      setError(MSG.error_db + err.message);
    }
  };

  // 목록보기 버튼 이벤트
  const handle_btnToListClick = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.substring(0, currentPath.lastIndexOf('/'));
    window.location.href = basePath;  
  };

  // 취소 버튼 이벤트
  const handle_btnCancelClick = () => {
    navigate(-1);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>ERROR: {error}</div>;

  return (
    <table className='tbl_layout' cellSpacing='0' cellPadding='0' style={{ width: '100%', height: '100%', tableLayout: 'fixed' }}>
      <thead></thead>
      <tbody>
        <tr>
          <td style={{ textAlign: 'right', paddingTop: '10px' }}></td>
          <td style={{ width: '850px', textAlign: 'center', paddingTop: '10px' }}>

            {/* SUB MENU */}
            <SubMenu />

            {/* 게시판 목록 테이블 */}
            <table className='boardDetail' cellSpacing='0' cellPadding='0' style={{ width: '100%', tableLayout: 'fixed' }}>
              <thead></thead>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: '0px'}}>
                    <input
                      type="text"
                      value={data.title || ''}
                      onChange={(e) => setData({ ...data, title: e.target.value })}
                      placeholder="제목"
                      tabIndex={10}
                      ref={ref_title}
                      style={{ width: '100%', padding: '10px'}}
                      maxLength={100}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ minHeight: '200px', verticalAlign: 'top', paddingLeft: '0px' }}>
                    <textarea
                      value={data.content || ''}
                      onChange={(e) => setData({ ...data, content: e.target.value })}
                      placeholder="본문"
                      tabIndex={20}
                      style={{ width: '100%', height: '240px', padding: '10px' }}
                      maxLength={10000000}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            {/* BUTTONS */}
            <table style={{ width: '100%', marginTop: '10px' }}>
              <thead></thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }}></td>
                  <td>
                  </td>
                  <td style={{ width: '250px', textAlign: 'right' }}>
                    <div className='btn1' onClick={handle_btnCancelClick} tabIndex={30}>취소</div>
                    <div className='btn1' onClick={handle_btnSaveClick} tabIndex={40}>저장</div>
                    <div className='btn1' onClick={handle_btnToListClick} tabIndex={50}>목록</div>
                  </td>
                </tr>
              </tbody>
            </table>

          </td>
          <td style={{ textAlign: 'left' }}></td>
        </tr>
      </tbody>
    </table>
  );
}

export default Write;
