import React, { useState, useEffect } from 'react';
import { BrowserRouter, useNavigate, Link, useLocation  } from 'react-router-dom';
import '../Common/common.css';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();  // 현재 URL 정보 가져오기
    
    const [currentMainMenu, setCurrentMainMenu] = useState('');
    const handle_MainMenuClick = (menuId, path) => {
        setCurrentMainMenu(menuId);  // 상태값 변경
        navigate(path);  // 페이지 이동
      };

    // 현재 URL에 맞는 메뉴 활성화
    useEffect(() => {
        switch (location.pathname.toLowerCase())
        {
            case '/free':
                setCurrentMainMenu('community');
                break;
            case '/club':
                setCurrentMainMenu('club');
                break;
            case '/gallery':
                setCurrentMainMenu('Gallery');
                break;
            case '/market':
                setCurrentMainMenu('market');
                break;

        }
    }, [location.pathname]); // URL 변경 시마다 실행

    return (
        <header className="header">
            <nav>
                <table className='header_area' cellSpacing={0} cellPadding={0}>
                    <thead>
                    </thead>
                    <tbody>
                    <tr>
                        {/* LEFT */}
                        <td className='header_left' onClick={() => { window.location.href = '/'; }} style={{cursor: "pointer"}}>ASCII</td>
                        
                        {/* CENTER */}
                        <td className='header_center' style={{"height": "30px"}}>
                            
                        <Link to="/Free" onClick={(e) => { e.preventDefault(); handle_MainMenuClick('community', '/Free'); }}>
                                <div className='menu' id='mainmenu_community' active={currentMainMenu === 'community' ? 'true' : undefined} > 
                                    <p>Community</p>
                                </div>
                                </Link>

                                <Link to="/Free" onClick={(e) => { e.preventDefault(); handle_MainMenuClick('club', '/Free'); }}>
                                <div className='menu' id='mainmenu_club' active={currentMainMenu === 'club' ? 'true' : undefined} > 
                                    <p>Club</p>
                                </div>
                                </Link>

                                <Link to="/Gallery" onClick={(e) => { e.preventDefault(); handle_MainMenuClick('gallery', '/Gallery'); }}>
                                <div className='menu' id='mainmenu_gallery' active={currentMainMenu === 'gallery' ? 'true' : undefined} > 
                                    <p>Gallery</p>
                                </div>
                                </Link>

                                <Link to="/market" onClick={(e) => { e.preventDefault(); handle_MainMenuClick('market', '/market'); }}>
                                <div className='menu' id='mainmenu_market' active={currentMainMenu === 'market' ? 'true' : undefined} > 
                                    <p>Market</p>
                                </div>
                            </Link>

                        </td>
                        
                        {/* RIGHT */}
                        <td className='header_right'>
                            <a href="/login">
                                <div className='menu'>
                                    <p>로그인</p>
                                </div>
                            </a>
                        </td>

                    </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </nav>
        </header>
    );
}

export default Header;