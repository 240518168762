import React from 'react';
import '../Common/common.css';

function Footer() {
    return (
        <div className="footer">
            <p>Copyright &copy; 2024 ASCII SOFT. All rights reserved.</p>
        </div>
    );
}

export default Footer;