// API URL
export const API_URL = "http://board.asciisoft.co.kr:7101";

// 공통 텍스트
export const MSG = {
  welcome: "Welcome to our app!",
  error: "An error occurred. Please try again later. ",
  error_db: "데이터를 불러오는 중 오류가 발생했습니다. ",
  warning_needwrite: "게시글을 작성해주세요. "
};

// 공통 색상
export const COLOR = {
  primary: "#3498db",
  secondary: "#2ecc71"
};