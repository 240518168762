import React, { useState, useEffect } from 'react';
import { BrowserRouter, useNavigate, Link, useLocation  } from 'react-router-dom';
import '../Common/common.css';

function SubMenu() {

    // SUB MENU 컨트롤
    const navigate = useNavigate();
    const location = useLocation();  // 현재 URL 정보 가져오기
    const [currentSubMenu, setCurrentSubMenu] = useState('');
    const handle_SubMenuClick = (menuId, path) => {
        setCurrentSubMenu(menuId);  // 상태값 변경
        navigate(path);  // 페이지 이동
    };
    // 현재 URL에 맞는 SUBMENU 활성화
    useEffect(() => {
        if (location.pathname.toLowerCase().startsWith('/free')) {
            setCurrentSubMenu('free');
          } else if (location.pathname.toLowerCase() === '/sub2') {
            setCurrentSubMenu('sub2');
          } else if (location.pathname.toLowerCase() === '/sub3') {
            setCurrentSubMenu('sub3');
          }
    }, [location.pathname]); // URL 변경 시마다 실행

    return (
        <header className="header">
           {/* 중고장터, 직접홍보 */}
           <table className="tbl_submenu" cellSpacing="0" cellPadding="0" style={{width:'100%'}}>
              <thead></thead>
              <tbody>
                <tr>
                  <td className="item" onClick={(e) => { e.preventDefault(); handle_SubMenuClick('free', '/Free'); }} active={currentSubMenu === 'free' ? 'true' : undefined}>자유게시판</td>
                  <td className="item" onClick={(e) => { e.preventDefault(); handle_SubMenuClick('sub2', '/Free'); }} active={currentSubMenu === 'sub2' ? 'true' : undefined}>SUB 2</td>
                  <td className="item" onClick={(e) => { e.preventDefault(); handle_SubMenuClick('sub3', '/Free'); }} active={currentSubMenu === 'sub3' ? 'true' : undefined}>SUB 3</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
        </header>
    );
}

export default SubMenu;