import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, MSG } from '../../Common/common';
import SubMenu from '../../Layout/SubMenu';
import { useParams } from 'react-router-dom';  // URL 파라미터를 받기 위해 필요

function Detail() {
  const { idx } = useParams(); // URL에서 idx 값을 가져옴
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 로딩 상태
  const [recommandPoint, setRecommandPoint] = useState(false); 
  const [discourageActive, setDiscourageActive] = useState(false); 
  const userid = '';
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/board/free/detail?idx=${idx}&userid=${userid}`); // API URL
        setData(response.data); 
        //console.log('data:' + data);
      } catch (err) {
        console.log(err.message);
        setError(MSG.error_db + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [idx]);

  if (loading) return <div></div>;
  if (error) return <div>ERROR: {error}</div>;

  // 목록보기 버튼 이벤트
  const handle_btnToListClick = () => {
    const currentPath = window.location.pathname;
    window.location.href = currentPath.substring(0, currentPath.lastIndexOf('/'));  
  };

  // 관리요청 버튼 이벤트
  const handle_btnRequestBlindClick = () => {
    alert('기능구현 예정');
  };

  // 편집 버튼 이벤트
  const handle_btnEditClick = () => {
    const currentPath = window.location.pathname;
    window.location.href = currentPath.substring(0, currentPath.lastIndexOf('/')) + '/Write';  
  };

  // 삭제 버튼 이벤트
  const handle_btnRemoveClick = () => {
    alert('기능구현 예정');
  };

  const handle_RecommandPoint = (point) => {
    setRecommandPoint(point);
    // alert('기능구현 예정');
  };



  return (
    <table className='tbl_layout' cellSpacing='0' cellPadding='0' style={{ width: '100%', height: '100%', tableLayout: 'fixed' }}>
      <thead></thead>
      <tbody>
        <tr>
          <td style={{ textAlign: 'right', paddingTop: '10px' }}></td>
          <td style={{ width: '850px', textAlign: 'center', paddingTop: '10px' }}>

            {/* SUB MENU */}
            <SubMenu />

            {/* 게시판 목록 테이블 */}
            <table className='boardDetail' cellSpacing='0' cellPadding='0' style={{ width: '100%', tableLayout: 'fixed' }}>
              <colgroup>
                <col />
                <col width='50' />
                <col width='125' />
                <col width='70' />
                <col width='40' />
                <col width='60' />
                <col width='40' />
                <col width='10' />
              </colgroup>
              <thead>
              </thead>
              <tbody>
                <tr>
                  <th colSpan={8}>{data.title}</th>
                </tr>
                <tr>
                  <td style={{textAlign: 'left' }}>{data.username}</td>
                
                  <td className='head'>작성일</td>
                  <td className='date font1'>{data.regdate}</td>
                
                  <td className='head'>조회수</td>
                  <td className='number font1'>{data.readcnt}</td>

                  <td className='head'>추천</td>
                  <td className='number font1'>{data.readcnt}</td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={8} style={{ minHeight: '160px', verticalAlign: 'top'}}>
                    <div style={{width: '100%', minHeight: '100px'}}>
                      {data.content}
                    </div>
                    <br />
                    <div style={{marginTop: '20px', marginBottom: '15px'}}>
                      <div className='btnRecommand' active={recommandPoint > 0 ? 'true' : 'false'} onClick={(e) => { handle_RecommandPoint(1); }} ><p>공감</p></div>
                      <div className='btnDiscourage' active={recommandPoint < 0 ? 'true' : 'false'} onClick={(e) => { handle_RecommandPoint(-1); }} ><p>비공감</p></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* BUTTONS */}
            <table style={{width: '100%', marginTop: '10px'}}>
              <thead></thead>
              <tbody>
                <tr>
                  <td style={{textAlign: 'left'}}>
                    <div className='btn1' onClick={(e) => { handle_btnRequestBlindClick(); }} >관리요청</div>
                    
                  </td>
                  <td></td>
                  <td style={{width: '250px', textAlign: 'right'}}>
                    <div className='btn1' onClick={(e) => { handle_btnRemoveClick(); }}>삭제</div>
                    <div className='btn1' onClick={(e) => { handle_btnEditClick(); }}>편집</div>
                    <div className='btn1' onClick={(e) => { handle_btnToListClick(); }} >목록</div>
                  </td>
                </tr>
              </tbody>
            </table>


          </td>
          <td style={{ textAlign: 'left' }}></td>
        </tr>
      </tbody>
    </table>
  );
}

export default Detail;
