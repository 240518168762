import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './Common/common.css';
import App from './App';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
//import reportWebVitals from './temp/reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <BrowserRouter>
    {/* MAIN MENU AREA */}
    <div style={{backgroundColor: "#cccccc", textAlign: "center"}}>
        <Header />
    </div>

    {/* CONTENTS AREA */}
    <div style={{height: "100%", overflowY: "auto"}}>
      <table cellPadding={0} cellSpacing={0} style={{width: '100%', height: '100%'}}>
      <thead></thead>
      <tbody>

        {/* CONTENTS */}
        <tr>
          <td style={{height: "100%" ,textAlign: "center", verticalAlign: "top"} }>
            <App /> 
          </td>
        </tr>

        {/* COPYRIGHT */}
        <tr>
          <td>
            <Footer />
          </td>
        </tr>
        
        </tbody>
        </table>
    </div>
    </BrowserRouter>

  </React.StrictMode>
  
);


//reportWebVitals(console.log);
